import { AxiosError, AxiosResponse } from 'axios';
import { getCurrentToken } from 'helpers/hooks/sendCurrentToken';
import { httpClient } from 'helpers/httpClient';
import { FeedbackData } from 'interfaces/Feedback/Feedback';
import {
  QueryParamsCategoriesProducts,
  QueryParamsProductData,
  ResponseCategoriesProducts,
  ResponseProductData,
} from 'interfaces/Product/ProductItem';

type FeedbackDataResponse = FeedbackData;

export const sendFeedback = async (
  data
): Promise<AxiosResponse<FeedbackDataResponse>> => {
  try {
    const response = await httpClient.post<FeedbackDataResponse>(
      '/contact_us',
      data
    );

    return response;
  } catch (e) {
    return e.response as AxiosError<unknown> as never;
  }
};

export const getProducts = async ({
  locale,
  geo,
  project,
  category_id,
  show_on_main_page,
}: QueryParamsProductData): Promise<AxiosResponse<ResponseProductData[]>> => {
  try {
    const config = {
      headers: getCurrentToken(),
      params: {
        locale,
        geo,
        project,
        category_id,
        show_on_main_page,
      },
    };

    const response = await httpClient.get<ResponseProductData[]>(
      '/packs',
      config
    );

    return response;
  } catch (e) {
    return e.response as AxiosError<unknown> as never;
  }
};

export const getCategoriesProducts = async ({
  locale,
}: QueryParamsCategoriesProducts): Promise<
  AxiosResponse<ResponseCategoriesProducts[]>
> => {
  try {
    const config = {
      headers: getCurrentToken(),
      params: {
        locale,
      },
    };

    const response = await httpClient.get<ResponseCategoriesProducts[]>(
      'packs/categories',
      config
    );

    return response;
  } catch (e) {
    return e.response as AxiosError<unknown> as never;
  }
};
