import {
  QueryParamsProductData,
  ResponseProductData,
} from 'interfaces/Product/ProductItem';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getProducts } from 'services/productPageService';
import { RootState } from 'stores/store';
import { IUserStore } from 'stores/userStore';
import { useDefaultCountryId } from './defaultCountryId';

type UpdateProductsWithChangeGeo = {
  productsData: ResponseProductData[];
  show_on_main_page?: boolean;
};

export const useUpdateProductsWithChangeGeo = ({
  productsData,
  show_on_main_page = false,
}: UpdateProductsWithChangeGeo) => {
  const geoStored = useSelector<RootState, IUserStore>(state => state.user);
  const geoDataStored = geoStored.geoData;
  const router = useRouter();
  const { locale: currentLanguage } = router as {
    locale: string;
  };
  const [products, setProducts] = useState(productsData);
  const defaultCountryId = useDefaultCountryId();

  const [prevCountryId, setPrevCountryId] = useState(defaultCountryId);

  const productsItemsResponse = async () => {
    setProducts([]);
    const readyData: QueryParamsProductData = {
      locale: currentLanguage,
      geo: geoDataStored,
    };
    if (show_on_main_page) {
      readyData.show_on_main_page = show_on_main_page;
    }
    if (router.query?.id) {
      readyData.category_id = Number(router.query.id);
    }
    const response = await getProducts(readyData);

    if (response?.data) {
      setProducts(response.data);
      setPrevCountryId(defaultCountryId);
    }
  };

  useEffect(() => {
    if (prevCountryId !== defaultCountryId) {
      productsItemsResponse();
      return;
    }
    setProducts(productsData);
  }, [geoDataStored, currentLanguage, router.query]);

  return { products };
};
