import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { Trans, useTranslation } from 'next-i18next';
import { ProductItemInterface } from 'interfaces/Product/ProductItem';
import { prettifyNum } from 'helpers/hooks/prettifyNum';
import { IOrderStore, setOrderData } from 'stores/orderStore';
import { IUserStore } from 'stores/userStore';
import { currency } from 'helpers/products';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { RootState } from 'stores/store';
import { handleChangeCart } from 'services/cartService';


import Link from 'next/link';
import ProductCounter from 'components/blocks/Product/ProductCounter';
import CartButton from 'components/blocks/Icons/CartButton';

import styles from './ProductItem.module.scss';
import stylesButton from 'components/blocks/Button/Button.module.scss';

interface IProductItem extends ProductItemInterface {
  setErrorMessage: (any) => void;
}

const ProductItem: React.FC<IProductItem> = ({
  name,
  image,
  id,
  additionalClass,
  amazon,
  notAvaliable,
  price,
  setErrorMessage,
}) => {
  const { t } = useTranslation(['products', 'feedback']);
  const dispatch = useDispatch();
  const router = useRouter();
  const { locale: currentLanguage } = router as {
    locale: string;
  };

  const userStored = useSelector<RootState, IUserStore>(state => state.user);
  const geoDataStored = userStored.geoData;
  const orderStored = useSelector<RootState, IOrderStore>(state => state.order);
  const orderDataStored = orderStored.orderData;

  const currencyLocale = geoDataStored === 'RU' ? 'ru' : 'en';

  const [isAmazon, setIsAmazon] = useState(false);
  const [productInCart, setProductInCart] = useState(false);
  const [initialCount, setInitialCount] = useState(1);

  useEffect(() => {
    setIsAmazon(geoDataStored === 'US' && Boolean(amazon));
  }, [geoDataStored]);

  useEffect(() => {
    const isInCart = orderDataStored.order_packs
      ? orderDataStored.order_packs.filter(item => item?.pack_id === id)
      : [];

    if (isInCart.length) {
      const currentProduct = isInCart[0];
      setInitialCount(currentProduct.quantity);
      setProductInCart(true);

      return;
    }

    setInitialCount(1);
    setProductInCart(false);
  }, [id, currencyLocale, orderDataStored]);

  const handleAddCart = useCallback(async () => {
    const alreadyInCart = orderDataStored.order_packs
      ? orderDataStored.order_packs.filter(item => item.pack_id === id)
      : [];

    handleChangeCart({
      id,
      quantity: alreadyInCart.length ? alreadyInCart[0].quantity + 1 : 1,
      language: currentLanguage,
      geo: geoDataStored,
      successCallback: data => {
        dispatch(setOrderData(data));
      },
      errorCallback: setErrorMessage,
    });
  }, [currentLanguage, geoDataStored, orderDataStored, id]);

  return (
    <div className={cn(styles.productItem, additionalClass)}>
      <Link href={isAmazon ? amazon : `/products/${id}`}>
        <a
          className={cn(styles.productItem__inner, 'link')}
          target={isAmazon ? '_blank' : ''}
        >
          {notAvaliable && (
            <div
              className={cn(
                styles.productItem__term,
                styles.productItem__termRed
              )}
            >
              {t('products.not_avaliable')}
            </div>
          )}
          <div className={styles.productItem__image}>
            <picture>
              <source
                srcSet='/images/products/product-item-placeholder.jpg'
                data-srcset={image?.join(',')}
                type='image/webp'
              />
              <img
                src='/images/products/product-item-placeholder.jpg'
                data-src={image?.[0]}
                alt={name}
                className={cn('img', 'lazyload')}
              />
            </picture>
          </div>
          <div className={styles.productItem__description}>
            <div className={styles.productItem__price}>
              <div className={styles.productItem__priceDiscount}>
                {prettifyNum(price) + currency[currencyLocale].sign}
              </div>
            </div>
            <div
              className={cn(styles.productItem__name, {
                [styles.productItem__nameMin]: name?.length > 20,
              })}
            >
              <Trans>{name}</Trans>
            </div>
          </div>
        </a>
      </Link>
      <div className={styles.productItem__buttons}>
        {!isAmazon && !productInCart && (
          <button
            className={cn(
              stylesButton.button,
              stylesButton.button__min2,
              styles.productItem__button
            )}
            disabled={notAvaliable}
            onClick={handleAddCart}
          >
            {t('products.buy')}
            <CartButton />
          </button>
        )}
        {!isAmazon && productInCart && (
          <ProductCounter
            id={id}
            initialCount={initialCount}
            addStyles={[styles.productItem__counter]}
            maxValue={1000}
            errorCallback={setErrorMessage}
          />
        )}
      </div>
    </div>
  );
};

export default ProductItem;
