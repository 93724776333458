import { useEffect, useMemo, useRef } from 'react';
import debounce from 'debounce-promise';

export const useDebounce = (callback, delay = 1000) => {
  const ref = useRef<any>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    function func(...args) {
      ref.current?.(...args);
    }

    return debounce(func, delay);
  }, []);

  return debouncedCallback;
};
